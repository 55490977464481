import React from 'react';

const NoFiles = () => {
    return (
        <div className='no-files'>
            <p className='gray700-14'>No files have been uploaded yet</p>
        </div>
    );
};

export default NoFiles;
