export const DISPLAY_DATE_STANDARD = 'd MMMM yyyy';
export const DISPLAY_DATE_SLASH = 'dd/MM/yyyy';

export const DISPLAY_TIME_STANDARD = 'HH:mm';

export const TEXTAREA_ROWS = 8;

export const DATASETS_STATUS_ACTIVE = 'active,draft';
export const STATUS_INREVIEW = 'inReview';
export const STATUS_REJECTED = 'rejected';
export const STATUS_ARCHIVE = 'archive';

export const SUPPORT_CREATE_URL = 'https://hdruk.atlassian.net/servicedesk/customer/portal/1/group/1/create/1';

export const ADVANCED_SEARCH_ROLE_GENERAL_ACCESS = 'GENERAL_ACCESS';
